import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { updatePage } from "actions/pageActions";
import type { UpdatePageRequest } from "api/PageApi";
import { getViewModePageList, getCurrentPage } from "selectors/editorSelectors";
import { isMobileLayout } from "@appsmith/selectors/applicationSelectors";
import { ReduxActionTypes } from "@appsmith/constants/ReduxActionConstants";
import EditablePageName from "pages/Editor/Explorer/Entity/Name";
import { resolveAsSpaceChar } from "utils/helpers";
import {
  useEntityUpdateState,
  useEntityEditState,
} from "@appsmith/pages/Editor/Explorer/hooks";
import { Switch } from "design-system-old";
import { Icon } from "design-system";
import { Alignment } from "@blueprintjs/core";
import IconSelect from "./IconSelect";
import { Spinner } from "design-system";
import { Tooltip } from "antd";

const TabBarContainer = styled.div<{
  isAdded?: boolean;
}>`
  border: ${(props) => (props.isAdded ? "none" : "2px dashed #999")};
  padding: ${(props) => (props.isAdded ? "0" : "12px")};
  width: ${(props) => (props.isAdded ? "160px" : "100%")};
  height: ${(props) => (props.isAdded ? "auto" : "56px")};
  margin-left: ${(props) => (props.isAdded ? "0" : "-6px")};
  position: relative;
`;

const PropertyControl = styled.div`
  margin-bottom: 12px;
`;

const AddSwitch = styled(Switch)`
  margin-bottom: 16px;
  font-size: 14px !important;
  font-weight: bold;
`;

const EditName = styled(EditablePageName)`
  .bp3-editable-text-content {
    justify-content: center;
  }
  .bp3-editable-text-input {
    text-align: center;
  }
`;

export interface TabbarProps {
  isAdded?: boolean;
}

const CLOSE_TABBAR = "CLOSE_TABBAR";

const TabBar = ({ isAdded }: TabbarProps) => {
  const dispatch = useDispatch();
  const pages = useSelector(getViewModePageList);
  const isFull = pages?.filter((p) => !!p.icon)?.length >= 5;
  const currentPage = useSelector(getCurrentPage);
  const pageId = currentPage?.pageId || "";
  const entityId = pageId + "_standalone";
  const pageName = currentPage?.pageName || "";
  const pageHidden = !!currentPage?.isHidden;
  const pageIcon: any = currentPage?.icon || "";
  const isUpdating = useEntityUpdateState(pageId);
  const isEditing = useEntityEditState(entityId);
  const switchDisabled = isFull && !pageIcon;
  const isMobile = useSelector(isMobileLayout);

  const onToggle = () => {
    onIconSelected(pageIcon ? CLOSE_TABBAR : "smile-o");
  };

  const startEditingName = () => {
    dispatch({
      type: ReduxActionTypes.INIT_EXPLORER_ENTITY_NAME_EDIT,
      payload: {
        id: entityId,
      },
    });
  };

  const onIconSelected = (icon?: string) => {
    if (icon) {
      const payload: UpdatePageRequest = {
        id: pageId,
        name: pageName,
        isHidden: pageHidden,
        icon: icon === CLOSE_TABBAR ? "" : icon,
      };
      dispatch(updatePage(payload));
    }
  };

  if (!isMobile) {
    return null;
  }

  if (!isAdded) {
    return (
      <TabBarContainer isAdded={isAdded}>
        <Tooltip
          title={
            switchDisabled
              ? "注意：底部导航栏已经满员，如果需要添加到底部导航栏，请先将不需要的页面移出"
              : "最多支持5个导航项"
          }
          placement="top"
          color={switchDisabled ? "red" : ""}
        >
          <div>
            <AddSwitch
              checked={!!pageIcon}
              large
              onChange={onToggle}
              disabled={switchDisabled}
              alignIndicator={Alignment.RIGHT}
            >
              添加到底部导航栏
            </AddSwitch>
          </div>
        </Tooltip>
        {isUpdating ? (
          <Spinner size="md" className="absolute right-[-24px] top-0" />
        ) : null}
      </TabBarContainer>
    );
  }

  return (
    <TabBarContainer isAdded={isAdded}>
      <Tooltip title="最多支持5个导航项" placement="top">
        <div>
          <AddSwitch
            checked={!!pageIcon}
            large
            onChange={onToggle}
            alignIndicator={Alignment.RIGHT}
          >
            添加到底部导航栏
          </AddSwitch>
        </div>
      </Tooltip>

      {pageIcon ? (
        <PropertyControl>
          <IconSelect value={pageIcon} onIconSelected={onIconSelected} />
        </PropertyControl>
      ) : null}

      <PropertyControl className="flex justify-center">
        <EditName
          className="text-center"
          entityId={entityId}
          isEditing={isEditing}
          name={pageName}
          nameTransformFn={resolveAsSpaceChar}
          updateEntityName={(name) =>
            updatePage({
              id: pageId,
              name,
              isHidden: pageHidden,
              icon: pageIcon,
            })
          }
          enterEditMode={startEditingName}
          exitEditMode={() => {}}
        />
        {isEditing ? null : (
          <Icon
            name="edit-white"
            className="cursor-pointer"
            onClick={startEditingName}
          />
        )}
      </PropertyControl>

      {isUpdating ? (
        <Spinner size="md" className="absolute right-[-36px] top-0" />
      ) : null}
    </TabBarContainer>
  );
};

export default TabBar;
