import * as React from "react";
import styled from "styled-components";
import { Alignment, Button, Classes, Menu, MenuItem } from "@blueprintjs/core";
import { ItemListRenderer, ItemRenderer, Select } from "@blueprintjs/select";
import { createVanIconComponent } from "@taroify/icons/van";
import ICON_NAMES from "components/propertyControls/taro/IconNames";
import { TooltipComponent } from "design-system-old";

const StyledButton = styled(Button)`
  box-shadow: none !important;
  border: none !important;
  border-radius: 0;
  background-color: #ffffff !important;

  .van-icon.taroify-icon {
    font-size: 48px;
  }

  > span.bp3-icon-caret-down {
    color: rgb(169, 167, 167);
  }
`;

const StyledMenu = styled(Menu)`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: minmax(50px, auto);
  gap: 8px;
  max-height: 170px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #eeeeee;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #939090;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  flex-direction: column;
  align-items: center;
  padding: 13px 5px;

  &:active,
  &:hover,
  &.bp3-active {
    background-color: #eeeeee !important;
  }

  .van-icon.taroify-icon {
    margin-right: 0;
    font-size: 20px;
    color: #555 !important;
  }
  > div {
    width: 100%;
    text-align: center;
    color: #939090 !important;
  }
`;

export interface IconSelectProps {
  value?: string;
  onIconSelected?: (icon?: string) => void;
}
type IconType = string;
const TypedSelect = Select.ofType<IconType>();

const renderVantIcon = (icon: string) => {
  let vantIcon = null;
  if (icon) {
    const VantIcon = createVanIconComponent(icon);
    vantIcon = <VantIcon />;
  }
  return vantIcon;
};

const renderMenu: ItemListRenderer<IconType> = ({
  itemsParentRef,
  renderItem,
  filteredItems,
}) => {
  if (!filteredItems.length) {
    return <div className="text-center p-2 text-gray-400">未找到相关图标</div>;
  }
  const renderedItems = filteredItems
    .map(renderItem)
    .filter((item) => item != null);
  return <StyledMenu ulRef={itemsParentRef}>{renderedItems}</StyledMenu>;
};

const renderIconItem: ItemRenderer<string> = (
  icon,
  { handleClick, modifiers },
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <TooltipComponent content={icon} key={icon}>
      <StyledMenuItem
        active={modifiers.active}
        icon={!icon ? undefined : renderVantIcon(icon)}
        key={icon}
        onClick={handleClick}
      />
    </TooltipComponent>
  );
};

const filterIconName = (query: string, iconName: string) => {
  return iconName.toLowerCase().indexOf(query.toLowerCase()) >= 0;
};

const IconSelect = (props: IconSelectProps) => {
  const { value: iconName } = props;
  const handleIconChange = (icon: IconType) => {
    props.onIconSelected?.(icon);
  };

  return (
    <TypedSelect
      className="icon-select-container"
      itemListRenderer={renderMenu}
      itemPredicate={filterIconName}
      itemRenderer={renderIconItem}
      items={ICON_NAMES}
      onItemSelect={handleIconChange}
      popoverProps={{ position: "top" }}
      inputProps={{ placeholder: "过滤..." }}
      activeItem={iconName}
      resetOnClose
    >
      <StyledButton
        alignText={Alignment.LEFT}
        className={Classes.TEXT_OVERFLOW_ELLIPSIS}
        fill
        icon={renderVantIcon(iconName || "")}
        rightIcon="caret-down"
      />
    </TypedSelect>
  );
};

export default IconSelect;
