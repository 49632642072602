import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  getViewModePageList,
  getCurrentPageId,
  getShowTabBar,
  previewModeSelector,
} from "selectors/editorSelectors";
import { getSelectedAppTheme } from "selectors/appThemingSelectors";
import { getAppMode } from "@appsmith/selectors/applicationSelectors";
import { APP_MODE } from "entities/App";
import { Tabbar } from "@taroify/core";
import { createVanIconComponent } from "@taroify/icons/van";
import history from "utils/history";
import { builderURL, viewerURL } from "@appsmith/RouteBuilder";
import IconPicker from "./TabBarIconPicker";
import { Popover } from "antd";
import { Icon as SystemIcon } from "design-system";

const TabBarContainer = styled.div<{
  mode?: APP_MODE;
  editorModeColor: string;
}>`
  position: ${(props) => (props.mode === APP_MODE.EDIT ? "unset" : "fixed")};
  left: 0;
  right: 0;
  bottom: 0;
  height: ${(props) => props.theme.tabbarHeight};
  background: ${(props) =>
    props.mode === APP_MODE.EDIT ? props.editorModeColor : "#ffec8f36"};
`;

const Center = styled.div`
  height: 100%;
  width: 450px;
  margin: 0 auto;
`;

const TabBar = () => {
  const pages = useSelector(getViewModePageList)?.filter((p) => !!p.icon);
  const currentPageId = useSelector(getCurrentPageId);
  const mode = useSelector(getAppMode);
  const showTabBar = useSelector(getShowTabBar);
  const themeBackground =
    useSelector(getSelectedAppTheme).properties.colors.backgroundColor;
  const isPreviewMode = useSelector(previewModeSelector);
  const editorModeColor = isPreviewMode ? themeBackground : "transparent";
  const isEditMode = mode === APP_MODE.EDIT && !isPreviewMode;

  const [popupVisible, setPopupVisible] = useState(false);

  const jumpTo = (target: string) => {
    if (target === currentPageId) {
      return;
    }
    const urlBuilder = mode === APP_MODE.PUBLISHED ? viewerURL : builderURL;
    history.push(
      urlBuilder({
        pageId: target,
      }),
    );
  };

  const togglePopup = useCallback(() => {
    setPopupVisible(!popupVisible);
  }, [popupVisible, setPopupVisible]);

  if (!showTabBar && !isEditMode) {
    return null;
  }

  return (
    <TabBarContainer mode={mode} editorModeColor={editorModeColor}>
      <Center>
        {showTabBar ? (
          <Tabbar value={currentPageId} onChange={jumpTo} className="ml-[-6px]">
            {pages.map((page) => {
              const { pageName, pageId, icon } = page;
              const Icon = createVanIconComponent(icon as any);
              if (pageId === currentPageId && isEditMode) {
                return (
                  <Popover
                    content={<IconPicker isAdded />}
                    trigger="click"
                    key={pageId}
                    open={popupVisible}
                  >
                    <Tabbar.TabItem
                      icon={<Icon />}
                      value={pageId}
                      onClick={togglePopup}
                      className="relative"
                    >
                      {pageName}
                      <SystemIcon
                        className="absolute right-2 top-2"
                        size="lg"
                        name={popupVisible ? "upArrow" : "downArrow"}
                      />
                    </Tabbar.TabItem>
                  </Popover>
                );
              } else {
                return (
                  <Tabbar.TabItem icon={<Icon />} key={pageId} value={pageId}>
                    {pageName}
                  </Tabbar.TabItem>
                );
              }
            })}
          </Tabbar>
        ) : (
          <IconPicker />
        )}
      </Center>
    </TabBarContainer>
  );
};

export default React.memo(TabBar);
